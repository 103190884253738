import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo";
import SectionTitle from "../components/sectiontitle";
import Layout from "../components/layout";

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    // experiment
    <Layout>
    <SEO lang="en" title="Listing of all tags used on Art of Geography" />

      <section id="taxonomy" className="container" style={{ minHeight: "600px" }}>
              <div className="section-title">
                  <SectionTitle title="Taxonomy of tags" />

                  <h3 className="tag-header">{tagHeader}</h3>
                  <div className="tag-box">
                    <ul className="tag-link-list">
                      {edges.map(({ node }) => {
                        const { slug } = node.fields
                        const { title } = node.frontmatter
                        return (
                          <li key={slug}>
                            <Link to={slug}>{title}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  {/*
                    This links to a page that does not yet exist.
                    You'll come back to it!
                */}
            <Link to="/tags"><strong>All tags</strong> used on the Art of Geography blog</Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`